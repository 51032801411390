import formatDate from 'dateformat';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import slugify from 'slugify';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import { Produit } from '../../types/produit';
import request from '../../utils/request';
import Pagination from '../Pagination/Pagination';
import styles from './ResultatsRecherche.module.scss';
import VignetteProduit from './VignetteProduit/VignetteProduit';

const query = loader('./getProducts.graphql');
const itemsPerPage = 15;

export interface Props extends RouteComponentProps {
	codeClient?: string;
	dateLivraison?: Date;
}

export function _ResultatsRecherche({
	location,
	codeClient,
	dateLivraison,
}: Props) {
	useTitle('Recherche');

	const [products, setProducts] = useState<Produit[]>([]);
	const [countTotal, setCountTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	// Set up date to get product by date
	const date = formatDate(new Date(), 'yyyy-mm-dd');

	// GET params
	const searchParams = new URLSearchParams(location.search);
	const pageIndex = +(searchParams.get('page') || 1);
	const activeSelection = searchParams.get('selection');
	const activeCategorie = searchParams.get('categorie');
	const activeLabel = searchParams.get('label');
	let activeQuery = searchParams.get('query');
	if (activeQuery) activeQuery = slugify(activeQuery, { lower: true });

	// À chaque changement de route
	useEffect(() => {
		let isMounted = true;
		setLoading(true);

		const params = {
			skip: itemsPerPage * pageIndex - itemsPerPage,
			first: itemsPerPage,
			where: {
				disabled: false,
				interdictions_every: {
					OR: [
						{ exceptclient: { code: codeClient } },
						{
							exceptgroupe: { clients_some: { code: codeClient } },
						},
						{
							AND: [
								{
									exceptclient: null,
								},
								{
									exceptgroupe: null,
								},
							],
						},
						{
							AND: [
								{
									OR: [
										{ NOT: { exceptclient: { code: codeClient } } },
										{
											NOT: {
												exceptgroupe: {
													clients_some: { code: codeClient },
												},
											},
										},
									],
								},
								{ NOT: { dateDebut_lte: date, dateFin_gte: date } },
							],
						},

						{
							produit: {
								autorisations_some: {
									AND: [
										{
											OR: [
												{ dateDebut_lte: date, dateFin_gte: date },
												{ dateDebut: null, dateFin: null },
											],
										},
										{
											client: { code: codeClient },
										},
									],
								},
							},
						},
					],
				},
				interdictions_none: {
					AND: [
						{
							OR: [
								{ client: { code: codeClient } },
								{ groupe: { clients_some: { code: codeClient } } },
								// { exceptgroupe: { clients_some: { code_not: codeClient } } },
								// { exceptclient: { code_not: codeClient } },
							],
						},
						{
							OR: [
								{ dateDebut_lte: date, dateFin_gte: date },
								{ dateDebut: null, dateFin: null },
							],
						},
						{
							produit: {
								autorisations_none: {
									AND: [
										{
											OR: [
												{ dateDebut_lte: date, dateFin_gte: date },
												{ dateDebut: null, dateFin: null },
											],
										},
										{
											client: { code: codeClient },
										},
									],
								},
							},
						},
					],
				},
				...(activeQuery && {
					AND: activeQuery.split('-').map((q) => ({
						OR: [
							{ code_contains: q.toUpperCase() },
							{ slug_contains: q.replace(/s$/, '') },
							{ categorie: { slug_contains: q } },
							{ selections_some: { slug_contains: q } },
							{ labels_some: { keywords_contains: q } },
						],
					})),
				}),
				...(activeSelection && { selections_some: { slug: activeSelection } }),
				...(activeCategorie && { categorie: { slug: activeCategorie } }),
				...(activeLabel && { labels_some: { slug: activeLabel } }),
			},
		};

		type TResponse = { countTotal: number; produits: Produit[] };
		request<TResponse>(query, params)
			.then(({ countTotal, produits }) => {
				if (!isMounted) return;
				setProducts(produits);
				setCountTotal(countTotal);
				setError('');
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [
		pageIndex,
		activeCategorie,
		activeLabel,
		activeQuery,
		activeSelection,
		codeClient,
		dateLivraison,
		date,
	]);

	return error ? (
		<p className={styles.warning}>{error}</p>
	) : (
		<>
			<Pagination
				showCount
				loading={loading}
				className={styles.pagination}
				totalItemsCount={countTotal}
				itemsCountPerPage={itemsPerPage}
			/>

			{/* Liste des produits */}
			<ul className={styles.list}>
				{loading
					? [...new Array(itemsPerPage)].map((_, i) => (
							<li key={i} className={styles.item}>
								<div className={styles.skeleton} />
							</li>
					  ))
					: products.map((produit) => (
							<li key={produit.code} className={styles.item}>
								<VignetteProduit {...produit} />
							</li>
					  ))}

				{/* Fakes (for flex display) */}
				{[...new Array(7)].map((_, i) => (
					<li key={i} className={[styles.item, styles.fake].join(' ')} />
				))}
			</ul>

			<Pagination
				loading={loading}
				className={styles.pagination}
				totalItemsCount={countTotal}
				itemsCountPerPage={itemsPerPage}
			/>
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		codeClient: state.compte.code,
		dateLivraison: state.panier.dateLivraison,
	};
}

export default withRouter(connect(mapStateToProps)(_ResultatsRecherche));
